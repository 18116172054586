import React from 'react'
import { Container,Row,Col } from 'react-bootstrap'
import './Languages.css'

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
//import ReactPlayer from "react-player";
import { useState,useEffect } from "react";
import {Link,useParams,useNavigate} from 'react-router-dom'
 //import { ReactVideo,ReactAudio } from "reactjs-media";


const Languagevideos = () => {
  // const videoObject = localStorage.getItem('url');
  const lan1 = localStorage.getItem('languageh');
  
  let navigate = useNavigate();

  const nextpage = (url,language,description) => {
  
    localStorage.setItem('url',url)

    localStorage.setItem('language',language)
    localStorage.setItem('description',description)


navigate  (`/videodetails`);
 
  };
 
  const [ popularMovies, setPopularMovies ] = useState([])
 
  useEffect(() => {
    fetch(`https://zing.beeinnovations.com:5002/zing-api/languageapi?language=${lan1}`,{
     method:"POST",
     
   })
   .then(res => res.json())
   .then(data => setPopularMovies(data.data))
   
  }, [])

  const filtered = popularMovies.filter(employee => {
  
          return employee.cat_type === 'video';
    
        });

  console.log(popularMovies) ;
  
   

  let settings = {
    dots: false,
    autoplay: false,
    autoplaySpeed: 3000,
    slidesToShow: 4,
  
    responsive: [{
      breakpoint: 600,
      settings: {
        slidesToShow: 1,
        // slidesToScroll: 2,
        // initialSlide: 2
      }
    }]

  };


  return (
    <>
       <Container className='p-2 m-5'>
    
        <Row>
          <Col>
          <div className="tag">
          <h1>{lan1} </h1>
    </div>
   <div className="main-cont" >
   
     
        <div {...settings}  className='slide' >
          {filtered.map ((item) => (
            <div key={item.id} className="imgcont"  >
              {/* <h1>{item.cat_type}</h1> */}
 
    

          {item.videodatamodel.slice(1,6).map((viwdeo) => 
                <div className="langcont" onClick={(e)=>nextpage(viwdeo.cp_url, viwdeo.language,viwdeo.description)}>
		  {/* <ReactPlayer className="langvideo" url={viwdeo.cp_url} type="video/mp4" /> */}
      <video className="langvideo"controls >
                      <source src={viwdeo.cp_url} type="video/mp4" />
                  </video>   
      <p style={{fontSize:'20px'}}>{viwdeo.name}</p>
                </div>
          // <ReactVideo src={video.cp_url}/>  
               
                           
             
                      )
            }
 
            {/* <h1>{item.videodatamodel}</h1> */}
            {/* <ReactPlayer url={item.cp_url} className="react-player" controls /> */}
            </div>
          ))}
        </div>
      </div>
  



   
          </Col>
        </Row>
       </Container>
      </>
  )
}

export default Languagevideos
