import React from 'react'
import { Container,Row,Col } from 'react-bootstrap'
import './videodetails.css'
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
//import ReactPlayer from "react-player";
import { useState,useEffect } from "react";
import {Link,useParams,useLocation} from 'react-router-dom'
 //import { ReactVideo,ReactAudio } from "reactjs-media";
//import {Player} from 'video-react';

const Videodetails = () => {
  const videoObject = localStorage.getItem('url');
  const lan1 = localStorage.getItem('language');
  const name1 = localStorage.getItem('description');

// const lan2=lan1.toLowerCase;

  console.log(name1);
  // const [ popularMovies, setPopularMovies ] = useState([])
  const [ popularMoviesT, setPopularMoviesT ] = useState([])
  useEffect(() => {
    fetch(`https://zing.beeinnovations.com:5002/zing-api/languageapi?language=${lan1}`,{
     method:"POST",
     
   })
   .then(res => res.json())
   .then(data => setPopularMoviesT(data.data))
   
  }, [])
  const filteredT = popularMoviesT.filter(employee => {
  
          return employee.cat_type === 'video';
    
        });
  console.log(popularMoviesT) ;
  
   

  let settings = {
    dots: false,
    autoplay: false,
    autoplaySpeed: 3000,
    slidesToShow: 4,
  
    responsive: [{
      breakpoint: 600,
      settings: {
        slidesToShow: 1,
        // slidesToScroll: 2,
        // initialSlide: 2
      }
    }]
  };

  let settings1 = {
    dots: false,
    autoplay: false,
    autoplaySpeed: 3000,
    slidesToShow: 1,
  
    responsive: [{
      breakpoint: 600,
      settings: {
        slidesToShow: 1,
        // slidesToScroll: 2,
        // initialSlide: 2
      }
    }]
  };
  return (
    <>
       <Container >
        <Row>
           <Col>
           <div className="tag">
          <h1>{lan1}</h1>
    </div>
{/*   
    <ReactPlayer className="main-cont" url={videoObject} type="video/mp4" />                   */}
                  <video className='main-cont' controls >
                                  <source src={videoObject} type="video/mp4" />
                              </video> 
{/*<Player className="main-cont"type="video/mp4"><source  src={videoObject} /></Player>*/}
{/*<ReactPlayer  url={videoObject} className='main-cont' /> */}
	<p style={{fontSize:'20px',textAlign:'left'}}>{name1}</p>
      
             
       
 
            {/* <h1>{item.videodatamodel}</h1> */}
{/* <ReactPlayer url={item.cp_url} className="react-player" controls /> */}
      
     
   

           </Col>
        </Row>
        <Row>
          <Col>
          <div className="tag">
      <h1>Suggested Videos</h1>
    </div>
   
        <div {...settings} width='auto' height='auto'>
          {filteredT.map ((item) => (
            <div key={item.id} className="imgcont"  >
              {/* <h1>{item.cat_type}</h1> */}
 
    

          {item.videodatamodel.slice(1,5).map((viwdeo) => 
                <div className='videocard'>
		  {/*<ReactPlayer className="allvideo-cont" url={viwdeo.cp_url} type="video/mp4" /> */}
      <video className='allvideo-cont' controls >
                      <source src={viwdeo.cp_url} type="video/mp4" />
                  </video>   
      <p style={{fontSize:'20px'}}>{viwdeo.name}</p>
                </div>
          // <ReactVideo src={video.cp_url}/>  
               
                           
             
                      )
            }
 
            {/* <h1>{item.videodatamodel}</h1> */}
            {/* <ReactPlayer url={item.cp_url} className="react-player" controls /> */}
            </div>
          ))}
        </div>
   
          </Col>
        </Row>
       </Container>
      </>
  )
}

export default Videodetails
