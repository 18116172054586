import { BrowserRouter, Route, Routes } from "react-router-dom";
import Login from "./components/Login/Login"; 
import Home from "./components/Home/Home";
import Videodetails from "./components/videodetails/Videodetails";
import Languagevideos from "./components/Category/Languagevideos";
import Music from "./components/Music/Music";


import Header from "./components/Header/Header";
// import Detail from "./components/Detail";
function App() {
  return (
    <>
      <BrowserRouter>

        <Routes>
          {/* <Route exact path="/" element={<Login />} /> */}
          <Route exact path="/Home" element={<Home />} />
          <Route exact path="/videodetails"element={<Videodetails />} />
          {/* <Route exact path="/Videodetails"element={<Videodetails />} /> */}

          <Route exact path="/langaugevideos"element={<Languagevideos />} />

 <Route path="/"element={<Music />} />
	  {/* <Route path="/detail/:id" element={<Detail />} /> */}
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
