import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./home.css"
import React from 'react';
import { useState,useEffect } from "react";
import {Link} from 'react-router-dom'
//import ReactPlayer from 'react-player/lazy'
//import {Player} from 'video-react';
// import { ReactVideo,ReactAudio } from "reactjs-media";

import { useNavigate   } from "react-router-dom";
import { Container,Row,Col } from 'react-bootstrap'


  
const Home = () => {
  let navigate = useNavigate();

  const nextpage1 = (url,language,description) => {
  
    localStorage.setItem('url',url)

    localStorage.setItem('language',language)
    localStorage.setItem('description',description)


navigate  (`/videodetails`);
 
};


const nextpageH = () => {
  
  // localStorage.setItem('url',url)
  localStorage.setItem('languageh','hindi')



navigate  (`/langaugevideos`);

};

const nextpageT = () => {
  
  // localStorage.setItem('url',url)
  localStorage.setItem('languageh','Telugu');



navigate  (`/langaugevideos`);

};

const nextpageE = () => {
  
  // localStorage.setItem('url',url)
  localStorage.setItem('languageh','English');



navigate  (`/langaugevideos`);

};

 const [ popularMovies, setPopularMovies ] = useState([])
 const [ popularMoviesT, setPopularMoviesT ] = useState([])
 const [ popularMoviesE, setPopularMoviesE ] = useState([])


 useEffect(() => {
  fetch("https://zing.beeinnovations.com:5002/zing-api/languageapi?language=hindi",{
   method:"POST",
   
 })
 .then(res => res.json())
 .then(json => 
  setPopularMovies(json.data))
console.log(popularMovies);
 
}, [])
const filtered = popularMovies.filter(employee => {

        return employee.cat_type === 'video';
  
      });
console.log(popularMovies) ;

 
useEffect(() => {
  fetch("https://zing.beeinnovations.com:5002/zing-api/languageapi?language=english",{
   method:"POST",
   
 })
 .then(res => res.json())
 .then(json => setPopularMoviesE(json.data))
 
}, [])
const filteredE= popularMoviesE.filter(employee => {

        return employee.cat_type === 'video';
  
      });
console.log(popularMoviesE) ;


  // const settings = {
  //   infinite: true,
  //   dots: true,
  //   slidesToShow: 6,
  //   slidesToScroll: 1,
  //   // lazyLoad: true,
  //   // autoplay: true,
  // autoplaySpeed: 2000,
   
  // };


  useEffect(() => {
    fetch("https://zing.beeinnovations.com:5002/zing-api/languageapi?language=telugu",{
     method:"POST",
     
   })
   .then(res => res.json())
   .then(json => setPopularMoviesT(json.data))
   
  }, [])
  const filteredT = popularMoviesT.filter(employee => {
  
          return employee.cat_type === 'video';
    
        });
  console.log(popularMoviesT) ;
  
   

  let settings = {
    dots: false,
    autoplay: false,
    autoplaySpeed: 3000,
    slidesToShow: 4,
  
    responsive: [{
      breakpoint: 600,
      settings: {
        slidesToShow: 1,
        // slidesToScroll: 2,
        // initialSlide: 2
      }
    }]
  };
  
  return (
    <>
    

 
 <Container>
 <Row>
  <Col>
  <div className="tag">
          <h1>English</h1>
          <h2 onClick={nextpageE} className="more">More</h2>

    </div>
         

   <div className="main-cont1">
    
        <Slider {...settings} className="slide">
          {filteredE.map ((item) => (
            <div key={item.id} className="imgcont" >
              {/* <h1>{item.cat_type}</h1> */}
 
      

          {item.videodatamodel.slice(0,1).map((viwdeo) => 
          
                <div className="homcont" onClick={(e)=>nextpage1(viwdeo.cp_url, viwdeo.language,viwdeo.description)}>
  
		  {/*<Player className="homvideo" type="video/mp4"><source  src={viwdeo.cp_url} /></Player>*/}
		  {/* <ReactPlayer className='homevideo' url={viwdeo.cp_url} />*/}
		    <video className="homvideo" controls >
                      <source src={viwdeo.cp_url} type="video/mp4" />
                  </video>
      <p style={{fontSize:'20px',margin:'10px'}}>{viwdeo.name}</p>
   
                </div>
          // <ReactVideo src={video.cp_url}/>  
               
                           
             
                      )
            }
 
            {/* <h1>{item.videodatamodel}</h1> */}
            {/* <ReactPlayer url={item.cp_url} className="react-player" controls /> */}
            </div>
          ))}
        </Slider>
     
      
   </div>

  </Col>
 </Row>
 <Row>
  <Col>
  <div className="tag">
          <h1>Hindi</h1>
          <h2 className="more" onClick={nextpageH}>More</h2>

    </div>
   <div className="main-cont1">
 
        <Slider {...settings} className="slide">
          {filtered.map ((item) => (
            <div key={item.id} className="imgcont" >
              {/* <h1>{item.cat_type}</h1> */}
 
    

          {item.videodatamodel.slice(0,1).map((viwdeo) => 
                <div className="homcont"  onClick={(e)=>nextpage1(viwdeo.cp_url, viwdeo.language,viwdeo.description)}>
		  {/*<Player className="homvideo" type="video/mp4"><source  src={viwdeo.cp_url} /></Player> */}
		  {/*<ReactPlayer className='homvideo' url={viwdeo.cp_url} /> */}
  <video className="homvideo" controls >
                      <source src={viwdeo.cp_url} type="video/mp4" />
                  </video>  
      <p style={{fontSize:'20px' ,margin:'10px'}}>{viwdeo.name}</p>
                </div>
          // <ReactVideo src={video.cp_url}/>  
               
                           
             
                      )
            }
 
            {/* <h1>{item.videodatamodel}</h1> */}
            {/* <ReactPlayer url={item.cp_url} className="react-player" controls /> */}
            </div>
          ))}
        </Slider>
      </div>
 

</Col>
 </Row>
 <Row>
<Col>

<div className="tag">
          <h1>Telugu</h1>
          <h2 className="more" onClick={nextpageT}>More</h2>

    </div>
   <div className="main-cont1" >

    
        <Slider {...settings}  className="slide" >
          {filteredT.map ((item) => (
            <div key={item.id} className="imgcont"  >
              {/* <h1>{item.cat_type}</h1> */}
 
    

          {item.videodatamodel.slice(0,1).map((viwdeo) => 
                <div className="homcont" onClick={(e)=>nextpage1(viwdeo.cp_url, viwdeo.language,viwdeo.description)}>
		  {/*<Player className="homvideo" type="video/mp4"><source  src={viwdeo.cp_url} /></Player>*/}
		  {/*<ReactPlayer className='homvideo' url={viwdeo.cp_url} /> */}
  <video className="homvideo" controls >
                      <source src={viwdeo.cp_url} type="video/mp4" />
                  </video>  
		  <p style={{fontSize:'20px' ,margin:'20px'}}>{viwdeo.name}</p>
                </div>
          // <ReactVideo src={video.cp_url}/>  
               
                           
             
                      )
            }
 
            {/* <h1>{item.videodatamodel}</h1> */}
            {/* <ReactPlayer url={item.cp_url} className="react-player" controls /> */}
            </div>
          ))}
        </Slider>
      </div>
   
</Col>
 </Row>
 </Container>


   



            </>
  )
}
export default Home;

